import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { BsPerson } from 'react-icons/bs';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import ImgTextModule from '../components/elements/ImgTextModule';

const Kontakt = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful },
  } = useForm();

  function onSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        axios.post('https://mailer.kdw-hr.de/', values);
        console.log(values);
        resolve();
      }, 1000);
    });
  }
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode();
  useEffect(() => {
    setColorMode(colormodeValue);
  });
  const [viewportHeight, setViewportHeight] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  useEffect(() => {
    setViewportHeight(window.innerHeight);
    setPageHeight(document.body.clientHeight);
    console.log('VIEWPORTHEIGHT: ' + window.innerHeight);
    console.log('PAGEHEIGHT: ' + document.body.clientHeight);
  }, []);
  const [colormodeValue, changeColorModeValue] = useState('light');
  return (
    <>
      <Helmet>
        <title>KDW | Kontakt</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>
      <ImgTextModule
        src={'All-1.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='65vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.red'
                fontWeight='normal'
                fontSize={{ base: '5xl', lg: '6xl' }}
              >
                Kontakt
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='white'
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='2xl'
              >
                Nutzen Sie zur Kontaktaufnahme das Kontaktformular.
              </Text>
            </FadeInAnimation>{' '}
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.green'
                mb={24}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='2xl'
              >
                Wir freuen uns, von Ihnen zu hören.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <Box bg={'brand.violet'}>
        <Container variant='layoutContainer' mt={0}>
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={{ base: 0, lg: 8 }} pt={32} minH='650px'>
              <Box p={{ base: 0, lg: 0 }} w='100%'>
                <Flex
                  w='100%'
                  flexDirection={{ base: 'column-reverse', md: 'row' }}
                  spacing={{ base: 20, sm: 3, md: 5, lg: 2 }}
                >
                  <WrapItem w={{ base: '100%', lg: '29%' }}>
                    <Box>
                      <Box py={{ base: 0, sm: 5, md: 8, lg: 0 }}>
                        <VStack pl={0} spacing={3} alignItems='flex-start'>
                          <Text color={'white'} fontSize={'xl'}>
                            kdw HR GmbH & Co. KG
                            <br />
                            Ulmenstrasse 21
                            <br />
                            26384 Wilhelmshaven
                            <br />
                          </Text>
                          <Link href='tel.: 04421 809900'>
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='sm'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.blue'
                            >
                              Telefon: 04421 809900
                            </Button>
                          </Link>
                          <Link href='mailto: whv@kdw-hr.de'>
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='sm'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.green'
                            >
                              E-Mail: whv@kdw-hr.de
                            </Button>
                          </Link>
                        </VStack>
                      </Box>
                      <Box py={{ base: 0, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems='flex-start'>
                          <Text color={'white'} fontSize={'xl'}>
                            kdw HR GmbH & Co. KG <br />
                            Standort Lübeck
                            <br />
                            Schützenhof 4
                            <br />
                            23558 Lübeck
                            <br />
                          </Text>
                          <Link href='Tel: 0451 39 77 88 90'>
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='sm'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.blue'
                            >
                              Tel. 0451 39 77 88 90
                            </Button>
                          </Link>
                          <Link href='mailto: luebeck@kdw-hr.de'>
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='sm'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.green'
                            >
                              E-Mail: luebeck@kdw-hr.de
                            </Button>
                          </Link>
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>

                  <WrapItem w={{ base: '100%', lg: '49%' }}>
                    <Box w='100%' bg='white' borderRadius='lg' pt={4} pb={12}>
                      <Box m={8} color='#0B0E3F'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <FormControl isInvalid={errors.name}>
                            <FormLabel mt={8}>Ihr Name</FormLabel>
                            <InputGroup borderColor='#E0E1E7'>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<BsPerson color='gray.800' />}
                              />
                              <Input
                                type='text'
                                size='md'
                                {...register('Name')}
                              />
                            </InputGroup>

                            <FormLabel mt={8}>e-Mail Adresse</FormLabel>
                            <InputGroup borderColor='#E0E1E7'>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<BsPerson color='gray.800' />}
                              />
                              <Input
                                type='text'
                                size='md'
                                {...register('EMail')}
                              />
                            </InputGroup>
                            <FormLabel mt={8}>Nachricht</FormLabel>
                            <Textarea
                              type='text'
                              size='md'
                              placeholder='Ihre Nachricht an uns'
                              name='nachricht'
                              {...register('Nachricht')}
                            />

                            <Button
                              mt={12}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='md'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.violet'
                              isLoading={isSubmitting}
                              type='submit'
                            >
                              Senden
                            </Button>
                          </FormControl>
                          <Box visibility={'hidden'}>
                            {isSubmitted &&
                              toast({
                                title: 'Vielen Dank für Ihre Nachricht',
                                description:
                                  'Wir melden uns so schnell es geht.',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                              })}
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  </WrapItem>
                </Flex>
              </Box>
            </Box>
          </FadeInAnimation>
        </Container>
      </Box>
    </>
  );
};

export default Kontakt;
